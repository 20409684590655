import type { Ref } from 'vue';
import { computed } from '../../../../.nuxt/imports';

export function useBaseInputType({
  type,
  showPassword,
  hiddenPlaceholder,
}: {
  type: Ref<string>
  showPassword: Ref<boolean>
  hiddenPlaceholder: Ref<boolean>
}) {
  const inputType = computed(() => {
    if (type.value === 'number') {
      return 'number';
    }

    if (type.value === 'password') {
      return showPassword.value ? 'text' : 'password';
    }

    if (hiddenPlaceholder.value) {
      return 'password';
    }

    return 'text';
  });

  return {
    inputType,
  };
}
