import { computed } from 'vue';

import type { Ref } from 'vue';

export function useBaseInputHiddenPlaceholder({
  showHiddenPlaceholder,
  model,
  inputFocused,
}: {
  showHiddenPlaceholder: Ref<boolean>
  model: Ref<string | number>
  inputFocused: Ref<boolean>
}) {
  const hiddenPlaceholder = computed(() => {
    if (showHiddenPlaceholder.value && !inputFocused.value && !model.value) {
      return true;
    }

    return false;
  });

  return {
    hiddenPlaceholder,
  };
}
