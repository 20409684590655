import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import type { Ref } from 'vue';

export function useBaseInputExpand({
  expandable,
  model,
  wrapperRef,
  inputRef,
}: {
  expandable: Ref<boolean>
  model: Ref<string | number>
  wrapperRef: Ref<HTMLElement>
  inputRef: Ref<HTMLElement>
}) {
  const isExpanded = ref(!!(expandable.value && model.value));

  function switchExpand() {
    if (isExpanded.value) {
      collapse();
    }
    else {
      expand();
    }
  }

  function expand() {
    if (expandable.value) {
      isExpanded.value = true;

      inputRef.value?.focus();
    }
  }

  function collapse() {
    if (expandable.value) {
      if (model.value && isExpanded.value) {
        return;
      }

      isExpanded.value = false;
    }
  }

  onClickOutside(wrapperRef, () => {
    collapse();
  });

  return {
    isExpanded,
    collapse,
    expand,
    switchExpand,
  };
}
